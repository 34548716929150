const logotext = "JP Tibério";
const meta = {
    title: "João Pedro Tibério",
    description: "I’m João Pedro data scientist _ Full stack devloper, currently working in São Paulo - Brazil",
};

const introdata = {
    title: "I’m João Pedro Tibério",
    animated: {
        first: "Leading with WordPress",
        second: "Delivering web solutions",
        third: "Boosting teams with DevOps",
        fourth: "Optimizing with Docker",
        fifth: "Scaling with AWS",
        sixth: "Automating with CI/CD",
        seventh: "Building with best practices",
        eighth: "Delivering impactful results"
    },
    your_img_url: "https://jptiberio.com.br/images/jptiberio.webp",
};

const dataabout = {
    title: "A bit about my self",
    aboutme: "My name is João Pedro Tibério, and I am a Full Stack Developer with a degree in Computer Science. With a strong focus on leadership and project management, I specialize in guiding teams through complex, high-impact projects. Whether in small startups or large organizations, I thrive on solving challenges and delivering innovative digital solutions. Programming and infrastructure are two of my greatest passions. Currently, I lead technology teams at Studio Visual agency, driving collaboration and excellence in every initiative. While programming and infrastructure remain core passions, my true strength lies in orchestrating teams and strategies to turn ambitious ideas into reality.",
};
const worktimeline = [{
        jobtitle: "Designer of week",
        where: "YAdfi",
        date: "2020",
    },
    {
        jobtitle: "Designer of week",
        where: "Jamalya",
        date: "2019",
    },
    {
        jobtitle: "Designer of week",
        where: "ALquds",
        date: "2019",
    },
];

const skills = [{
        name: "Devops",
        value: 100,
    },
    {
        name: "WordPress",
        value: 100,
    },
    {
        name: "Javascript",
        value: 90,
    },
    {
        name: "PHP",
        value: 90,
    },
    {
        name: "Tailwind",
        value: 80,
    },
];

const services = [{
        title: "Wordpress Developer",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Wordpress Developer",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Wordpress Developer",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
];

const dataportfolio = [{
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/800/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },

    {
        img: "https://picsum.photos/400/600/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/550/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
];

const contactConfig = {
    YOUR_EMAIL: "jptiberio@icloud.com",
    YOUR_FONE: "+55 (11) 91578-8533",
    description: "I provide expert consulting in Web Solutions, Website Development, Systems, and Infrastructure. Let’s work together to enhance your online presence with professional, tailored web services that drive measurable results. Contact me today to discuss how we can bring your vision to life.",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_0ukxzn7",
    YOUR_TEMPLATE_ID: "template_udtdywj",
    YOUR_USER_ID: "b9Lx5S8aQTGDqS93B",
};

const socialprofils = {
    github: "https://github.com/jtiberio-sv",
    facebook: "https://www.facebook.com/jp.tib",
    linkedin: "https://www.linkedin.com/in/jo%C3%A3o-pedro-tib%C3%A9rio-11901965/",
    twitter: "https://x.com/jptiberio",
    whatsapp: "https://api.whatsapp.com/send?phone=+5511915788533",
    instagram: "https://www.instagram.com/jp.tiberio/",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};